<template>
  <div>
    <v-card class="pa-8" v-if="!loading">
      <header-content :pageTitle="'List Admin'" />

      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          outlined
          rounded
          dense
          color="primary"
          label="Cari kata kunci disini"
          prepend-inner-icon="mdi-magnify"
        ></v-text-field>
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-none d-lg-block text-none"
          @click="$router.push('/input-admin')"
          >Tambah Admin</v-btn
        >
        <v-btn
          color="primary"
          rounded
          class="ml-4 white--text d-lg-none d-md-block text-none"
          @click="$router.push('/input-admin')"
          >Tambah</v-btn
        >
      </div>

      <v-data-table
        :headers="headers"
        :items="admins"
        :items-per-page="10"
        :search="searchQuery"
      ></v-data-table>
    </v-card>

    <v-card class="pa-8" v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
  </div>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  data() {
    return {
      searchQuery: "",
      loading: false,
      headers: [
        {
          text: "id",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "Admin Code", value: "admin_code" },
        { text: "Name", value: "name" },
        { text: "Username", value: "username" },
        { text: "Email", value: "email" },
      ],
      admins: [],
    };
  },

  methods: {
    async getAdmins() {
      this.loading = false;

      await store.dispatch("admin/getAdmins").then((r) => {
        if (r) {
          this.loading = false;
        }
      });
      this.admins = store.state.admin.admins;
    },
  },

  mounted() {
    this.getAdmins();
  },
};
</script>
